import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import { FilesActions } from './files.actions';

@Injectable()
export class FilesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly fileUploadService = inject(fromGenerated.FileUploadService);

  uploadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesActions.uploadFiles),
      mergeMap(({ fileUploadTransactionId, file }) =>
        this.fileUploadService
          .fileUploadControllerUploadFile(fileUploadTransactionId, file)
          .pipe(
            map((response) =>
              FilesActions.uploadFilesSuccess({
                file: { ...response, name: file.name },
              })
            ),
            catchError((error: Error) =>
              of(
                FilesActions.uploadFilesFailure({
                  fileName: file.name,
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );
}
